.text-container {
  width: 50%;
  text-align: center;
  transition: all 3s;
}

.text-overlay {
  font-size: 40px;
  font-weight: 800;
  color: black;
  text-align: center;
}

@media (max-width: 768px) {
  .text-container {
    margin-top: 30px;
    width: 100%;
  }
  .text-overlay {
    font-size: 30px;
    font-weight: 800;
    color: black;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .text-overlay {
    font-size: 20px;
    font-weight: 800;
    color: black;
    text-align: center;
  }
}
