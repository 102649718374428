

.main {
    padding: 10px 10px 10px 300px;
}

@media (max-width:1199px) {
    .main {
        padding: 10px 10px 10px 10px;
    }
}