.discount-badge {
  background-color: red;
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: 10px;
  opacity: 0; /* 기본적으로 숨김 상태 */
  transition: opacity 1s ease-in-out; /* 페이드 인/아웃 효과 */
}

.fade-in {
  opacity: 1; /* 애니메이션 완료 후 보이기 */
}

.fade-out {
  opacity: 0; /* 애니메이션이 진행 중일 때 숨기기 */
}

@media (max-width: 768px) {
  .discount-badge {
    padding: 2px 5px;
    font-size: 18px;
  }
}
