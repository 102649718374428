@font-face {
    font-family: 'Pangram';
    src: url('./Pangram-Black.woff2') format('woff2'),
        url('./Pangram-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pangram';
    src: url('./Pangram-Regular.woff2') format('woff2'),
        url('./Pangram-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pangram';
    src: url('./Pangram-ExtraLight.woff2') format('woff2'),
        url('./Pangram-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pangram';
    src: url('./Pangram-Light.woff2') format('woff2'),
        url('./Pangram-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pangram';
    src: url('./Pangram-Bold.woff2') format('woff2'),
        url('./Pangram-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pangram';
    src: url('./Pangram-Medium.woff2') format('woff2'),
        url('./Pangram-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pangram';
    src: url('./Pangram-ExtraBold.woff2') format('woff2'),
        url('./Pangram-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

