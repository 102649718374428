.scroll-section-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.scroll-image-container {
  position: relative;
  width: 75%;
  height: 900px; /* 컨테이너의 높이 조정 */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* 이미지가 테두리를 넘지 않도록 설정 */
}

.image-wrapper {
  position: relative;
  width: 100%; /* 컨테이너의 폭 조정 */
  height: 55%; /* 컨테이너의 높이 조정 */
  border-radius: 20px; /* 둥근 모서리 */
  box-sizing: border-box; /* 테두리를 포함한 박스 모델 설정 */
  overflow: hidden; /* 테두리를 넘는 이미지 부분 숨기기 */
}

.before-img,
.after-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* 이미지 비율 유지하면서 컨테이너에 맞게 조정 */
}

.before-img {
  /* transition: clip-path 0.2s ease-out; */
  z-index: 2; /* 앞 이미지가 뒤 이미지 위에 있도록 설정 */
}

.after-img {
  z-index: 1; /* 뒤 이미지 */
}

@media (max-width: 768px) {
  .scroll-section-container {
    flex-direction: column;
  }
  .scroll-image-container {
    width: 100%;
    height: 400px; /* 컨테이너의 높이 조정 */
  }

  .image-wrapper {
    width: 90%; /* 컨테이너의 폭 조정 */
  }
}
