html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button {
  font-family: 'Pangram';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

li {
  list-style: none;
}

a,
a:hover {
  text-decoration-line: none !important;
}

body {
  font-family: 'Poppins', sans-serif !important;
}

.active_sidebar {
  overflow: hidden;
}

.form-control:focus {
  box-shadow: none !important;
}

.html,
body {
  background-color: white;
}

.container.col-md-4 {
  width: 100%;
}

.container.col-md-4 .stats_list.mb-3 {
  width: 23%;
}

.prototype-scroll h2 {
  margin: 0;
  color: #000;
  left: calc(0% + 130px);
  text-align: left;
  max-width: 470px;
  position: absolute;
}

.prototype-scroll img {
  /* width: 350px;
  height: 500px; */
}

.prototype-scroll section {
  height: 80vh;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  perspective: 500px;
  scroll-snap-type: y mandatory;
}

.prototype-scroll section > div {
  width: 100%;
  max-width: 1320px;
  /* height: 450px; */
  position: relative;
  max-height: 90vh;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 5px;
  text-align: end;
}

.section_heading {
  width: 100%;
  font-size: 52px;
  font-weight: 800;
  line-height: 67.6px;
}

.section_heading_sub {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
}

.kickstarter-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 100%;
}

.kickstarter-button {
  font-size: 20px;
  font-weight: 700;
  background-color: white;
  color: black;
  border-radius: 20px;
  padding: 15px 20px;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  transition: background-color 0.5s ease;
}
.kickstarter-button:hover {
  background-color: #2ecc71;
  color: white;
}

.kickstarter-button-container-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 100%;
}

.kickstarter-button-bottom {
  font-size: 20px;
  font-weight: 700;
  background-color: white;
  color: black;
  border-radius: 20px;
  padding: 15px 20px;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  transition: background-color 0.5s ease;
}
.kickstarter-button-bottom:hover {
  background-color: #2ecc71;
  color: white;
}

.notify-section {
  margin-bottom: 80px;
}

.notify-section .notify-card {
  background-color: #fff;
  /* border: 1px solid #F1F3F5; */
  padding: 30px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.notify-section .notify-card .form-label {
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;
}

.notify-section .notify-card .form-control {
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  background: #f8f9fa;
  border-radius: 0;
  border: 0;
  padding: 22px 30px;
}

.notify-section .notify-card .form-control::placeholder {
  color: #ced4da;
}

.notify-section .notify-card .btn.btn-primary {
  padding: 20px 30px;
  border: 0;
  border-radius: 0;
  font-size: 18px;
  font-weight: 500;
  margin-top: 30px;
}

.purchase-count {
  background-color: #f8f9fa;
  padding-top: 100px !important;
}

.purchase-count .count-heading {
  font-size: 42px;
  font-weight: 800;
  line-height: 54.6px;
}

.purchase-count .purchase-content p.launchtext {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  color: #212529;
}

.purchase-count .purcahse-count-item ul li h4 {
  background-color: #fff;
  height: 65px;
  width: 65px;
  padding-top: 16px;
  font-weight: 800;
}

.purchase-count ul li span {
  font-size: 13px;
}

.purchase-count-bottom {
  background-color: white;
  padding-top: 20px !important;
}

.purchase-count-bottom .count-heading {
  font-size: 42px;
  font-weight: 800;
  line-height: 54.6px;
}

.purchase-count-bottom .purchase-content p.launchtext {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  color: #212529;
}

.purchase-count-bottom .purcahse-count-item ul li h4 {
  background-color: #f8f9fa;
  height: 65px;
  width: 65px;
  padding-top: 16px;
  font-weight: 800;
}

.purchase-count-bottom ul li span {
  font-size: 13px;
}
.sale-badge {
  position: relative;
  color: #000000; /* 기본 글자 색상 */
}

.sale-badge::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ff0000; /* 빨간색 삭선 */
  transform: translateY(-50%) rotate(-10deg); /* 텍스트 중앙에 맞추기 */
  z-index: 1;
}
.slide {
  background-color: #fff;
}

.sliderSection .swiper-slide.swiper-slide img {
  width: 100%;
  /* aspect-ratio: 1/0.7; */
  height: 300px;
  object-fit: cover;
}

.sliderSection .swiper-slide.swiper-slide {
  margin-right: 15px !important;
}

.swiper {
  position: relative;
}

.slide .control-btn {
  position: absolute;
  bottom: -242px;
}

.sliderSection .swiper-button-prev,
.sliderSection .swiper-button-next {
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #f1f3f5;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  display: inline-block;
  margin: 10px;
  z-index: 1049;
  cursor: pointer;
}

.sliderSection .swiper-button-prev::after {
  content: url('./assets/images/arrow-left.svg');
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sliderSection .swiper-button-next::after {
  content: url('./assets/images/arrow-right.svg');
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sliderSection .swiper::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 80%;
  right: 0;
  bottom: 60px;
  background: #f1f3f5;
  z-index: 0;
}

/* .slide .control-btn button.btn {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50%;
}

.slide .control-btn button.btn:active {
  border-color: #fff;
}

.slide .control-btn hr {
  border: 1px solid #F1F3F5;
  opacity: 1.25;
} */

.herobanner .sliderImage {
  width: 100%;
  aspect-ratio: 48/25;
  /* background-repeat: no-repeat; */
  object-fit: contain;
}

.gtm-img-slide {
  transition: transform 0.3s ease;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 6px;
}

.gtm-img-slide:hover {
  transform: scale(0.99);
}

.herobanner .banner-content {
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  max-width: 1050px;
}
.main-heading {
  margin-bottom: 1.5rem;
}

.herobanner .main-heading {
  font-size: 65px;
  font-weight: 800;
  line-height: 75px;
}

.herobanner .main-heading .crossed-price::after {
  position: absolute;
  content: url(./assets/images/price-cross.svg);
  top: -10px;
  left: 0px;
  /* max-width: 110px; */
  width: 100%;
  overflow: hidden;
}

.herobanner .main-heading .offerSpan {
  font-size: 20px;
  font-weight: 600;
  line-height: 30.04px;
  background-color: #ff3434;
  padding: 10px;
  color: #fff;
}

.herobanner .banner-date-text {
  font-size: 42px;
  font-weight: 800;
  line-height: 42px;
}

.herobanner .launching-text {
  font-size: 60px;
  font-weight: 800;
  line-height: 68.91px;
}

.cta-section {
  position: fixed;
  bottom: 30px;
  z-index: 1049;
}

.cta-section .cta-card {
  background-color: #198dfa;
  padding: 30px;
}

.cta-section .cta-card .cta-heading {
  font-size: 26px;
  font-weight: 800;
  line-height: 33.8px;
}

.cta-section .cta-card .btn-white {
  font-size: 16px;
  font-weight: 600;
  padding: 18px 30px;
  border-radius: 0;
}

.videosection-heading span {
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
}

.videosection-heading {
  font-size: 56px;
  font-weight: 800;
  line-height: 56px;
}

.video-section .videoDiv {
  aspect-ratio: 3/2;
}

.video-section .video-discription {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #212529;
}

.footer .footerWave-div {
  /* background-image: url('./assets/images/footer-img.png'); */
  height: 150px;
}

.footer .footerbackground {
  background-color: #2493fa;
}

.footer .footerWave {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 99;
  opacity: 0.8;
}

.footer .footerWave.waveSecond {
  z-index: 9;
  opacity: 0.6;
}

.footer .footerWave.waveThird {
  z-index: 9;
  opacity: 0.4;
}

/* responsive css starts here  */
@media (max-width: 1399.98px) {
  .herobanner .banner-content {
    top: 40px;
  }

  .herobanner .main-heading {
    font-size: 55px;
  }

  .herobanner .banner-date-text {
    font-size: 32px;
  }
}

@media (max-width: 1199.98px) {
  .kickstarter-button-container {
    margin-top: 20px;
  }

  .kickstarter-button {
    border-radius: 20px;
    padding: 10px 15px;
    font-weight: 700;
    font-size: 2rem;
  }

  .kickstarter-button-container-bottom {
    margin-top: 20px;
  }

  .kickstarter-button-bottom {
    border-radius: 20px;
    padding: 10px 15px;
    font-weight: 700;
    font-size: 2rem;
  }

  .cta-section .cta-card .btn-white {
    font-size: 16px;
    padding: 8px 20px;
  }
  .prototype-scroll h2 {
    position: static;
  }

  .videosection-heading {
    font-size: 36px;
    font-weight: 800;
    line-height: 40px;
  }

  .videosection-heading span {
    font-size: 20px;
    line-height: 22px;
  }
  .section_heading {
    font-size: 42px;
    line-height: 57.6px;
  }
  .section_heading_sub {
    font-size: 30px;
  }
}

@media (max-width: 991.98px) {
  .herobanner .banner-content {
    top: 20px;
  }

  .sliderSection .swiper::after {
    display: none;
  }

  .herobanner {
    margin-bottom: 0px;
  }

  .herobanner .main-heading {
    font-size: 35px;
  }

  .herobanner .main-heading .offerSpan {
    font-size: 20px;
    line-height: 41.04px;
    padding: 2px 20px;
  }

  .herobanner .launching-text {
    font-size: 40px;
    font-weight: 800;
    line-height: 48.91px;
  }

  /* .herobanner .banner-content {
    top: 100%;
    background-color: #313131;
    height: 360px !important;
    padding: 20px 0;
  } */
  .banner-content {
    font-size: 10px;
  }

  .section_heading {
    font-size: 32px;
    line-height: 47.6px;
  }

  .section_heading_sub {
    font-size: 20px;
  }

  .herobanner .main-heading .crossed-price::after {
    top: 0px;
    left: 0px;
    /* max-width: 110px; */
    width: 100%;
    overflow: hidden;
  }

  .herobanner .banner-date-text {
    font-size: 30px;
    line-height: 0px;
  }

  .herobanner .launching-text {
    font-size: 30px;
    font-weight: 800;
    line-height: 0px;
  }
  #kickstarter-logo {
    height: 30px; /* Keep the logo aspect ratio */
  }

  .purchase-count .count-heading {
    font-size: 32px;
  }

  .purchase-count .purchase-content p.launchtext {
    font-size: 18px;
  }

  .videosection-heading {
    font-size: 25px;
    font-weight: 800;
    line-height: 30px;
  }

  .gtm-img-slide {
    height: 300px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .main-heading {
    line-height: 15px !important;
  }
  .kickstarter-button-container {
    margin-top: 10px;
  }

  .kickstarter-button {
    border-radius: 15px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 1.5rem;
  }

  .kickstarter-button-container-bottom {
    margin-top: 20px;
  }

  .kickstarter-button-bottom {
    border-radius: 20px;
    padding: 10px 15px;
    font-weight: 700;
    font-size: 1.5rem;
  }

  .swiper {
    overflow: hidden !important;
    max-width: 100% !important;
    margin: 0 !important;
  }
  .herobanner .banner-content {
    top: 10px;
  }

  .herobanner .main-heading {
    font-size: 25px;
  }
  .herobanner .main-heading .offerSpan {
    font-size: 14px;
    line-height: 31.04px;
    padding: 2px 20px;
  }

  .banner-content {
    font-size: 10px;
  }

  .section_heading {
    font-size: 28px;
    line-height: 47.6px;
  }

  .section_heading_sub {
    font-size: 20px;
  }

  .herobanner .main-heading .crossed-price::after {
    top: 0px;
    left: 0px;
    /* max-width: 110px; */
    width: 100%;
    overflow: hidden;
  }

  .herobanner .banner-date-text {
    font-size: 25px;
    line-height: 0px;
  }

  .herobanner .launching-text {
    font-size: 25px;
    font-weight: 800;
    line-height: 0px;
  }
  #kickstarter-logo {
    height: 25px; /* Keep the logo aspect ratio */
  }

  .purchase-count .count-heading {
    font-size: 25px;
  }
  .purchase-count .purchase-content p.launchtext {
    font-size: 15px;
  }

  .videosection-heading {
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
  }

  .gtm-img-slide {
    height: 250px;
    object-fit: contain;
  }

  .sliderSection .swiper-slide.swiper-slide img {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 6px;
    width: 90% !important;
    height: 300px !important;
    object-fit: cover;
  }
}

@media (max-width: 600.98px) {
  .main-heading {
    line-height: 15px !important;
    margin-bottom: 0.5rem !important;
  }
  .kickstarter-button-container {
    margin-top: 10px;
  }

  .kickstarter-button {
    border-radius: 15px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 1rem;
  }

  .herobanner .main-heading .crossed-price::after {
    top: 0px;
  }

  .prototype-scroll section {
    height: 50vh;
  }

  .sliderSection .swiper-button-prev,
  .sliderSection .swiper-button-next {
    height: 40px;
    width: 40px;
  }

  .herobanner .main-heading {
    font-size: 18px;
    line-height: 0px;
  }
  .herobanner .banner-content {
    top: 3px;
  }

  .herobanner .main-heading .offerSpan {
    font-size: 15px;
    line-height: 31.04px;
    padding: 2px 10px;
  }

  .herobanner .main-heading .crossed-price::after {
    top: -9px;
    left: 0px;
    /* max-width: 110px; */
    width: 100%;
    overflow: hidden;
  }

  .herobanner .banner-date-text {
    font-size: 15px;
    line-height: 0px;
  }

  .herobanner .launching-text {
    font-size: 15px;
    font-weight: 800;
    line-height: 0px;
  }
  #kickstarter-logo {
    height: 16px; /* Keep the logo aspect ratio */
  }

  .gtm-img-slide {
    height: 250px;
    object-fit: cover;
  }
}

/* responsive css ends here  */

/* Custom styles for SweetAlert2 */

.custom-confirm-button {
  background-color: #007bff; /* Customize the OK button color */
  color: white; /* Ensure text color is visible */
}

@media (max-width: 900px) {
  .custom-popup {
    width: 90%; /* Make the alert wider on smaller screens */
    max-width: none; /* Remove the max width constraint on very small screens */
    height: 70%;
  }
  .swal2-title {
    font-size: 1.2em;
  }
  #swal2-html-container {
    font-size: 13px;
  }
  #swal2-html-notice {
    font-size: 8px;
  }
  .custom-confirm-button {
    font-size: 13px;
  }
}
@media (max-width: 550px) {
  .custom-popup {
    width: 90%; /* Make the alert wider on smaller screens */
    max-width: none; /* Remove the max width constraint on very small screens */
    height: 90%;
  }
  .swal2-title {
    font-size: 1.2em;
  }
  #swal2-html-container {
    font-size: 10px;
  }
  #swal2-html-notice {
    font-size: 7px;
  }
  .custom-confirm-button {
    font-size: 11px;
  }
  #kickstarter-logo {
    height: 15px; /* Keep the logo aspect ratio */
  }
}

@media (max-width: 1300px) {
  /* CTA */
  .cta-section .cta-card {
    padding: 20px;
  }

  .cta-section .cta-card .cta-heading {
    font-size: 16px;
    line-height: 33.8px;
  }

  #gtm-button-floating-notifyme {
    font-size: 16px;
    padding: 4px 8px;
  }

  /* slider */
  .sliderSection .swiper-button-prev,
  .sliderSection .swiper-button-next {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 1000px) {
  /* CTA */
  .cta-section .cta-card {
    padding: 20px;
  }

  .cta-section .cta-card .cta-heading {
    font-size: 13px;
    line-height: 33.8px;
  }

  #gtm-button-floating-notifyme {
    font-size: 13px;
    padding: 4px 8px;
  }
  /* slider */
  .sliderSection .swiper-button-prev,
  .sliderSection .swiper-button-next {
    height: 45px;
    width: 45px;
  }
}

@media (max-width: 768px) {
  /* CTA */
  .cta-section .cta-card {
    padding: 10px;
  }

  .cta-section .cta-card .cta-heading {
    font-size: 12px;
    line-height: 33.8px;
  }

  #gtm-button-floating-notifyme {
    font-size: 12px;
    padding: 2px 5px;
  }

  .notify-section .notify-card .form-control {
    font-size: 14px;
    font-weight: 500;
    line-height: 23.4px;
    background: #f8f9fa;
    border-radius: 0;
    border: 0;
    padding: 22px 25px;
  }

  .notify-section .notify-card .form-label {
    font-size: 14px;
    font-weight: 700;
    line-height: 23.4px;
  }
  .country-box {
    width: 20% !important;
  }

  .phone-box {
    width: 80% !important;
  }
}

@media (max-width: 500px) {
  .kickstarter-button-container {
    margin-top: 10px;
  }

  .kickstarter-button {
    border-radius: 10px;
    padding: 2px 10px;
    font-weight: 700;
    font-size: 0.8rem;
  }
  .custom-popup {
    width: 90%; /* Make the alert wider on smaller screens */
    max-width: none; /* Remove the max width constraint on very small screens */
    height: 90%;
  }
  .swal2-title {
    font-size: 1.2em;
  }
  #swal2-html-container {
    font-size: 10px;
  }
  #swal2-html-notice {
    font-size: 7px;
  }
  .custom-confirm-button {
    font-size: 11px;
  }
  #kickstarter-logo {
    height: 13px; /* Keep the logo aspect ratio */
  }

  .notify-section .notify-card .form-control {
    font-size: 12px;
    font-weight: 400;
    line-height: 23.4px;
    background: #f8f9fa;
    border-radius: 0;
    border: 0;
    padding: 22px 20px;
  }

  .notify-section .notify-card .form-label {
    font-size: 13px;
    font-weight: 700;
    line-height: 23.4px;
  }
}
