.stripe_title_container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 20px;
}

.section_sub {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  width: 80%;
  text-align: left;
}

.stripe_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
}

.stripe_button {
  padding: 20px;
  border-radius: 20px;
  font-size: 30px;
  font-weight: 800;
  background-color: #f7c438;
  color: white;
  width: 50%;
  cursor: pointer;
  border: none;
}

@media (max-width: 768px) {
  .section_sub {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    width: 80%;
    text-align: left;
  }

  .stripe_button {
    font-size: 25px;
    font-weight: 700;
    width: 90%;
  }
}

@media (max-width: 500px) {
  .stripe_button {
    font-size: 20px;
  }
}
