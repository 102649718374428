.container-default {
  max-width: 1318px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 150px;
}

.card-container {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
}
.card-content {
  display: flex;
  flex-direction: row;
}

.card {
  background-color: white;
  box-shadow: 0 3px 20px 0 rgba(8, 15, 52, 0.06);
  border-radius: 24px;
  overflow: hidden;
}

.img-section {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-section {
  width: 60%;
}
.card-page {
  display: flex;
  flex-direction: row;
}

.card .card-page {
  box-shadow: 0 2px 8px 0 rgba(8, 15, 52, 0.06);
  padding: 0;
}

.card-text-wrapper {
  color: #222223;
  text-align: center;
  padding: 25px 30px;
  font-size: 26px;
  font-weight: 700;
  line-height: 38px;
}

.color-primary {
  color: #f85757;
}
.gradient-text {
  font-size: 3rem;
  background: linear-gradient(45deg, #ff6b6b, #f06595);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.text-section-title {
  font-size: 35px;
  padding: 15px;
}

.text-section-content {
  font-size: 30px;
  padding: 20px;
}
.wavy-blue-gradient-text {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(45deg, #00aaff, #0055ff, #00c6ff, #007aff);
  background-size: 300% 300%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: gradientWaves 5s linear infinite;
  padding: 20px;
}

/* Gradient Animation */
@keyframes gradientWaves {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@media (max-width: 1300px) {
  .container-default {
    margin-bottom: 100px;
  }
  .card-text-wrapper {
    font-size: 20px;
    line-height: 30px;
  }
  .text-section-title {
    font-size: 25px;
  }
  .text-section-content {
    font-size: 20px;
  }
  .wavy-blue-gradient-text {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .container-default {
    margin-bottom: 100px;
  }
  .card-container {
    font-size: 20px;
    line-height: 30px;
  }

  .text-section-title {
    font-size: 18px;
    padding: 10px;
  }
  .text-section-content {
    font-size: 20px;
    padding: 0px;
  }
  .wavy-blue-gradient-text {
    font-size: 1rem;
  }

  .card-content {
    flex-direction: column !important;
  }
  .img-section {
    width: 100%;
  }
  .text-section {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .image-section {
    height: 50%;
  }
  .container-default {
    margin-bottom: 100px;
  }
  .card-text-wrapper {
    padding: 10px 10px;
  }
  .text-section-title {
    font-size: 15px;
    padding: 0px 5px;
  }
  .text-section-content {
    font-size: 15px;
    padding: 5px 0px;
  }
  .wavy-blue-gradient-text {
    font-size: 1.1rem;
    padding: 10px 0px;
  }
  .img-section {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-section {
    width: 100%;
  }
}
